<template>
  <div>
    <h1>All symbols</h1>

    <Navbar />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  name: "Settings",
  components: { Navbar },
};
</script>

<style>
</style>